import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from "baseui/modal";
import PropTypes from "prop-types";
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";
import UserApiService from "../../modules/user/services/UserApiService";
import { getErrors, renderErrors } from "../../libs/Api/NextApiResponseUtility";
import { showNotification } from "../../libs/NotificationUtility";
import { MESSAGE_TYPES } from "../../libs/Types";

const ForgotPasswordModal = ({ showModal, setShowModal }) => {
  const FORGOT_PASSWORD_STATE = {
    DEFAULT: "DEFAULT",
    FETCHING: "FETCHING",
    FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
    FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_ERROR",
  };

  const [email, setEmail] = useState("");
  const [state, setState] = useState(FORGOT_PASSWORD_STATE.DEFAULT);

  const handleModalClose = () => {
    setShowModal(false);
    setEmail("");
    setState(FORGOT_PASSWORD_STATE.DEFAULT);
  };

  const handleSendForgotPasswordEmail = () => {
    setState(FORGOT_PASSWORD_STATE.FETCHING);
    UserApiService.fetchSendForgotPasswordEmail(email)
      .then((res) => {
        setState(FORGOT_PASSWORD_STATE.FORGOT_PASSWORD_SUCCESS);
        setShowModal(false);
        showNotification(
          "Falls Du über ein Benutzerkonto verfügst, wurde eine E-Mail zum Zurücksetzen des Passworts an Dich versendet.",
          MESSAGE_TYPES.SUCCESS
        );
      })
      .catch((e) => {
        console.log(e);
        const errors = getErrors(e.rawResponse);
        const errorText = renderErrors(errors);
        setState(FORGOT_PASSWORD_STATE.FORGOT_PASSWORD_ERROR);
        if (errorText) {
          showNotification(errorText, MESSAGE_TYPES.ALERT);
        }
      });
  };

  return (
    <>
      <Modal isOpen={showModal} onClose={handleModalClose}>
        <ModalHeader>E-Mail zum Ändern des Passworts anfordern</ModalHeader>
        <ModalBody>
          <p>
            Wir senden Dir per E-Mail einen Link zu, mit welchem Du anschließend
            das Passwort ändern kannst.
          </p>
          <FormControl label={() => "E-Mail"}>
            <Input
              name="username"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type={"email"}
              required
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={handleSendForgotPasswordEmail}
            disabled={[
              FORGOT_PASSWORD_STATE.FETCHING,
              FORGOT_PASSWORD_STATE.FORGOT_PASSWORD_SUCCESS,
            ].includes(state)}
            isLoading={state === FORGOT_PASSWORD_STATE.FETCHING}
          >
            Weiter
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

ForgotPasswordModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};

export default ForgotPasswordModal;
