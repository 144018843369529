import Link from "next/link";
import React, { useEffect } from "react";
import { getLayout } from "../layouts/DefaultPageLayout";
import ServersidePageUtility from "../libs/ServersidePageUtility";
import LoginForm from "../components/LoginForm/LoginForm";
import UserApiService from "../modules/user/services/UserApiService";
import { showNotification } from "../libs/NotificationUtility";
import { MESSAGE_TYPES } from "../libs/Types";
import logger, { createLogPayload } from "../libs/logger";
import { renderErrors } from "../libs/Api/NextApiResponseUtility";
import { providers, csrfToken } from "next-auth/client";
import {
  getMergedApiErrorsAndMessages,
  isSuccessResponse,
} from "../libs/Api/TYPO3ApiResponseUtility";
import PropTypes from "prop-types";
import getConfig from "next/config";

const Login = ({ messages, username, csrfToken }) => {
  const { publicRuntimeConfig } = getConfig();

  useEffect(() => {
    // render serverside generated messages
    messages.map((message, i) => {
      showNotification(message.text, message.type);
    });
  }, []);

  const handleLoginFailure = (errorName, username) => {
    let message = "";
    let messageOptions = {};
    const messageType = MESSAGE_TYPES.ALERT;
    switch (errorName) {
      case "UserOrPasswordIncorrect":
        message = "Der Benutzer oder das Passwort sind nicht korrekt";
        break;
      case "UserWithPendingOptin":
        message = (
          <>
            Du hast deine E-Mail noch nicht bestätigt. Bitte klicke auf den
            Bestätigungslink in der Opt-in Mail.
            <br />
            <br />
            Falls Du die E-Mail nicht finden kannst:
          </>
        );
        messageOptions = {
          actionMessage: "Erneut versenden",
          actionOnClick: () => {
            UserApiService.fetchConfirmEmailAgain(username).then(() => {
              showNotification(
                "Opt-In E-Mail wurde erneut versendet.",
                MESSAGE_TYPES.SUCCESS
              );
            });
          },
        };
        break;
      default:
        message = "Fehler beim Login aufgetreten";
    }
    showNotification(message, messageType, messageOptions);
  };

  return (
    <div className="page-login">
      <div className="grid-x grid-padding-y">
        <div className="cell large-6 large-offset-3">
          <h1>Dein Bewerber-Login</h1>
          <LoginForm
            username={username}
            csrfToken={csrfToken}
            userType="candidate"
            defaultRedirectAfterSuccessfulLoginUrl={`${publicRuntimeConfig.pagePaths.jobSearch}?login=1`}
            onFailure={handleLoginFailure}
          />
        </div>
        <div className="cell large-6 large-offset-3 ce--space-before-extra-small">
          <h3>Noch keinen Bewerber-Login?</h3>
          <p>
            Registriere Dich jetzt kostenlos für Deutschlands größten
            juristischen Stellenmarkt und profitiere von maßgeschneiderten
            Job-Alerts sowie vielen weiteren Funktionen.
          </p>
          <Link href={publicRuntimeConfig.pagePaths.candidateRegistrationPage}>
            <a className="button button--primary">Zur Registrierung</a>
          </Link>
        </div>
        <div className="cell large-6 large-offset-3 ce--space-before-extra-small">
          <h3 className="ce--space-after-none">
            Sind Sie Arbeitgeber und möchten sich einloggen?
          </h3>
          <Link href={publicRuntimeConfig.pagePaths.employerLogin}>
            <a className="button button--primary ce--space-before-extra-small">
              Zum Arbeitgeber-Login
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

const apiErrorOverrides = {
  1586934661: {
    // User auth token is wrong
    message: "Fehler bei der Aktivierung deines Accounts.",
  },
  1586933964: {
    // User status is not set to REST_STATUS_CONFIRMATION
    message: "Fehler bei der Aktivierung deines Accounts.",
  },
  1586934056: {
    // User is activated already
    message: "Dein Account ist bereits aktiviert.",
  },
  1586934116: {
    // No user found to activate
    message: "Dies ist kein gültiger Link zum Freischalten deines Accounts.",
  },
};

/**
 * Handle user confirm
 * @param confirmParam
 * @returns {Promise<[]>}
 */
const handleUserConfirm = async (confirmParam) => {
  const [userId, token] = confirmParam.split("-");
  const messagesToShow = [];
  let username = "";

  const processErrorResponse = (resp) => {
    const filteredApiErrors = getMergedApiErrorsAndMessages(
      resp,
      apiErrorOverrides
    );
    logger.error(
      createLogPayload(
        {
          user: {
            uid: userId,
            token,
          },
          errors: filteredApiErrors,
        },
        "USER_CONFIRM.ERROR"
      ),
      `Candidate activate error [uid:${userId}]`
    );

    messagesToShow.push({
      type: MESSAGE_TYPES.ALERT,
      text: renderErrors(filteredApiErrors),
    });
  };

  try {
    const userConfirmResponse = await UserApiService.fetchUserConfirm(
      userId,
      token
    );

    if (isSuccessResponse(userConfirmResponse)) {
      logger.info(
        createLogPayload(
          {
            user: {
              uid: userId,
              token: token,
            },
          },
          "USER_CONFIRM.SUCCESS"
        ),
        `Candidate activated [uid: ${userId}`
      );
      messagesToShow.push({
        type: MESSAGE_TYPES.SUCCESS,
        text: "Benutzer erfolgreich aktiviert.",
      });
      username = userConfirmResponse.result?.user?.email || "";
    } else {
      processErrorResponse(userConfirmResponse);
    }
  } catch (e) {
    processErrorResponse(e.rawResponse);
  }
  return { messagesToShow, username };
};

export async function getServerSideProps(context) {
  const defaultPageProps = await ServersidePageUtility.defaultPageProps(1);

  const { query } = context;

  // confirm user if param exists
  let messagesToShow = [];
  let username = "";
  if (query.confirm) {
    ({ messagesToShow, username } = await handleUserConfirm(query.confirm));
  }

  return {
    props: {
      csrfToken: await csrfToken(context),
      messages: messagesToShow,
      username: username || "",
      ...defaultPageProps,
      customDimensions: {
        page_type: "candidate_login",
      },
    },
  };
}

Login.getLayout = getLayout;

Login.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.shape({ type: PropTypes.string, text: PropTypes.string })
    )
  ),
  username: PropTypes.string,
  csrfToken: PropTypes.string,
};

export default Login;
