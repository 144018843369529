import React, { useState } from "react";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { signIn } from "next-auth/client";
import { useRouter } from "next/router";
import styles from "./LoginForm.module.scss";
import { showNotification } from "../../libs/NotificationUtility";
import { MESSAGE_TYPES } from "../../libs/Types";
import ForgotPasswordModal from "./ForgotPasswordModal";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import Button from "../Button";

const LoginForm = ({
  username = "",
  userType = "candidate",
  defaultRedirectAfterSuccessfulLoginUrl = "/",
  onFailure,
}) => {
  const router = useRouter();
  const [user, setUser] = useState({ userType, username: username || "" });
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [pendingRequest, setPendingRequest] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setPendingRequest(true);
    const response = await signIn(`${userType}`, {
      ...user,
      redirect: false,
    });

    if (!response?.error) {
      Sentry.setUser({ email: user?.username || "" });
      showNotification("Erfolgreich angemeldet", MESSAGE_TYPES.SUCCESS);
      const params = new URLSearchParams(window.location.search);
      const redirectTo = params.get("callbackUrl");
      const redirectUrl = redirectTo
        ? `${window.location.origin}${redirectTo.replace(/^.*\/\/[^\/]+/, "")}`
        : defaultRedirectAfterSuccessfulLoginUrl;
      router.push(redirectUrl);
    } else if (response?.error) {
      onFailure && onFailure(response?.error, user?.username);
      setPendingRequest(false);
    }
  };

  const handleForgotPasswordClick = () => {
    setShowForgotPasswordModal(true);
  };

  return (
    <div className={styles.formWrapper}>
      <form
        method="post"
        onSubmit={handleLogin}
        className="ce--space-before-extra-small"
      >
        <div className="grid-x">
          <div className="cell large-12">
            <FormControl label={() => "E-Mail"}>
              <Input
                name="username"
                value={user.username}
                onChange={(e) => setUser({ ...user, username: e.target.value })}
                required
              />
            </FormControl>
          </div>
          <div className="cell large-12">
            <FormControl
              label={() => "Passwort"}
              caption={() => (
                <a onClick={handleForgotPasswordClick}>Passwort vergessen?</a>
              )}
            >
              <Input
                name="password"
                type="password"
                value={user.password}
                onChange={(e) => setUser({ ...user, password: e.target.value })}
                required
              />
            </FormControl>
          </div>
          <div className="cell large-12">
            <Button
              type="submit"
              disabled={pendingRequest}
              isLoading={pendingRequest}
              className="button button--primary"
            >
              Anmelden
            </Button>
          </div>
        </div>
      </form>
      <ForgotPasswordModal
        showModal={showForgotPasswordModal}
        setShowModal={setShowForgotPasswordModal}
      />
    </div>
  );
};

LoginForm.propTypes = {
  username: PropTypes.string,
  userType: PropTypes.oneOf(["candidate", "employer"]),
  defaultRedirectAfterSuccessfulLoginUrl: PropTypes.string,
  onFailure: PropTypes.func,
};

export default LoginForm;
