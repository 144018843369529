export const createError = (message, code = 0) => {
  return {
    message: message || "Es ist ein Fehler aufgetreten!",
    code,
  };
};

export const createErrorResponse = (errors) => {
  return {
    errors,
  };
};

export const hasAllowedErrorResponse = (errors) => {
  return errors.some((error) => error._allowErrorResponse === true);
};

export const sendErrorResponse = (res, errors) => {
  // filter private error attributes
  const filteredErrors = errors.map((error) => {
    return Object.keys(error)
      .filter((key) => !key.startsWith("_"))
      .reduce((obj, key) => {
        obj[key] = error[key];
        return obj;
      }, {});
  });
  res.status(400).json({
    errors: filteredErrors,
  });
};

export const sendUnauthorizedResponse = (res, data = {}) => {
  res.setHeader("WWW-Authenticate", "FormBased");
  // to prevent issues on dev server with active basic auth we send 480 instead of 401
  res.status(480).json(data);
};

export const sendSuccessResponse = (res, data = {}) => {
  res.status(200).json(data);
};

export const createSuccessResponse = (data = {}) => {
  return data;
};

export const getErrors = (resp) => {
  return resp.errors;
};

export const renderErrors = (errors) => {
  if (errors && Array.isArray(errors)) {
    if (errors.length > 1) {
      return (
        <ul>
          {errors.map((error) => (
            <li key={error.code}>{error.message}</li>
          ))}
        </ul>
      );
    } else {
      return errors[0].message;
    }
  }
  return null;
};

// TODO move to BackendApiUtility
export const getErrorMessage = (response) => {
  console.log(response?.errors);
  return response?.errors?.[0]?.message;
};
